<template>
    <div class="Awarp">
        <dashCard class="lvscBox18" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption"
            @changeScreen="changeScreen">
            <template slot="title">
                <div style="display: flex;">
                    <span>{{commonLangHandler('chuneng11_overView','电池簇总览', getZEdata)}}</span>

                </div>
            </template>
            <!-- <template slot="Input">
                    <el-input v-model="input" placeholder="可查找编号"></el-input>
                </template> -->
            <template slot="aside">
<!--                <span style="">-->
<!--                  <el-input v-model="input" placeholder="可查找编号"-->
<!--                            @keyup.enter.native="sendMsg"></el-input>-->
<!--                </span>-->
                <a-radio-group v-model="searchObj.TYPE" button-style="solid" @change="handleChange">
                    <a-radio-button value="0">{{commonLangHandler('chuneng11_all','所有', getZEdata)}}</a-radio-button>
                    <a-radio-button value="1">{{commonLangHandler('chuneng11_collect','收藏夹', getZEdata)}}</a-radio-button>
                </a-radio-group>
            </template>
            <template slot="operate"></template>
            <div class="list-content">
                <a-table ref="aTable" :columns="columns" :data-source="detailInfo.list" :pagination="false"
                    :scroll="{ y: tableHeight }" rowKey="id">
                    <span slot="action" slot-scope="text,record">
<!--                        <i class="lowcode icon-weishoucang" @click="onEdit(record)"></i>-->
                        <img src="@/assets/imgs/noshoucang.png" @click="onEdit(record)" style="width: 16px;cursor: pointer" v-if="record.是否收藏 == 0 ">
                        <img src="@/assets/imgs/shoucang.png" @click="onEdit(record)" v-else style="width: 16px;cursor: pointer">
                    </span>
                </a-table>
<!--                <div class="pagination">-->
<!--                    <div class="pagination-sizes">-->
<!--                        <font>{{commonLangHandler('chuneng14_page','分页', getZEdata)}}</font>-->
<!--                        <span>1/10</span>-->
<!--                    </div>-->
<!--                    <div class="pagination-btngroup">-->
<!--                        <i class="lowcode icon-left"></i>-->
<!--                        <i class="lowcode icon-right"></i>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
          <div style="display: flex;justify-content: right">
            <el-pagination
                background
                layout="prev, pager, next"
                @current-change="currentChange"
                :page-size="page.pageSize"
                :current-page="page.currentPage"
                :total="page.total"
                @prev-click="preClick"
                @next-click="nextClick">
            </el-pagination>
          </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false"
            width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <lvscBox18 :option="option" :isModal="true" :orginSearchObj="searchObj" />
            </div>
        </a-modal>
    </div>
</template>
<script>
export default {
    name: 'lvscBox18',
    components: {
        dashCard: () => import('@/component/dashCard.vue'),
    },
    props: {
        gridOption: {
            type: Object,
            default: function () {
                return { w: 6, h: 6 }
            }
        },
        option: {
            type: Object,
            default: function () {
                return {
                    visible: false
                }
            }
        },
        isModal: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        orginSearchObj: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    watch: {
        gridOption: {
            handler: function (newVal, oldVal) {
                this.$nextTick(_ => {
                    this.getOperateWidth()
                    this.getElHeight()
                })
            },
            deep: true,
            immediate: true
        },
        "option.visible": {//全屏化时执行
            handler: function (newVal, oldVal) {
                if (newVal) {
                    if (this.isModal) {
                        this.searchObj = this.orginSearchObj
                        this.getData()
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
    data() {
        return {
            operateWidth: 64, //操作列宽度
            isLoading: false,//数据加载中
            page:{
              pageSize: 5,
              total: 1,
              currentPage:1
            },
            searchObj: {
                TYPE: "0",
            },
            input: "",
            tableHeight: 400,
            columns: [
                { title: this.commonLangHandler('chuneng11_number' , '电池簇编号', JSON.parse(sessionStorage.getItem('currentDataAll'))), dataIndex: '名称', key: 'word1', defaultSortOrder: 'descend', },
                { title: this.commonLangHandler('chuneng11_totalVoltage' , '总电压(V)', JSON.parse(sessionStorage.getItem('currentDataAll'))), dataIndex: '总电压', key: 'word2', defaultSortOrder: 'descend', },
                { title: this.commonLangHandler('photovoltaicBox14_current' , '电流(A)', JSON.parse(sessionStorage.getItem('currentDataAll'))), dataIndex: '电流', key: 'word3', defaultSortOrder: 'descend', },
                { title: 'SOC(%)', dataIndex: 'soc', key: 'word4', defaultSortOrder: 'descend', },
                { title: 'SOH(%)', dataIndex: 'soh', key: 'word5', defaultSortOrder: 'descend', },
                { title: this.commonLangHandler('chuneng11_operate' , '操作', JSON.parse(sessionStorage.getItem('currentDataAll'))), dataIndex: '是否收藏', key: 'x', width: 80, scopedSlots: { customRender: 'action' } },
            ],
            detailInfo: {
                list: []
            },
        }
    },
    computed: {
        currentSelectDeptInfo() {
            return this.$store.state.currentSelectDeptInfo
        },
        currentuserinfo(){
            return this.$store.state.currentuserinfo
        }
    },
    methods: {
        preClick(i){
          this.page.currentPage = i
          this.getData()
        },
        nextClick(i){
          this.page.currentPage = i
          this.getData()
        },
        currentChange(i){
          this.page.currentPage = i
          this.getData()
        },
        // 全屏切换
        changeScreen() {
            this.option.visible = !this.isModal
            if (!this.option.visible) {
                this.getData()
            }
        },
        // 右侧操作列宽度
        getOperateWidth() {
            if (!this.$el.querySelector) {
                return
            }
            var dom = this.$el.querySelector(".card-head-operate")
            if (!!dom) {
                var width = dom.clientWidth
                this.operateWidth = width > 0 ? width + 12 : width
            }
        },
        // 数字转为千位分隔符表示
        toThousandsSeparator: function (value) {
            if (!value) return 0
            // 获取整数部分
            const intPart = Math.trunc(value)
            // 整数部分处理，增加,
            const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
            // 预定义小数部分
            let floatPart = ''
            // 将数值截取为小数部分和整数部分
            const valueArray = value.toString().split('.')
            if (valueArray.length === 2) { // 有小数部分
                floatPart = valueArray[1].toString() // 取得小数部分
                return intPartFormat + '.' + floatPart
            }
            return intPartFormat + floatPart
        },


        handleChange(value) {
            //console.log(this.searchObj)
            this.getData()
        },
        sendMsg(){
            this.getData()
        },
        onEdit(record){
            //console.log(this.currentuserinfo);
            if (record.是否收藏 == 1) {
                let data = {
                    deptname:record.名称,
                    type:'0',
                    assetNumber:record.code,
                    userName:this.currentuserinfo.USERNAME,
                    typeName:"zonglan",
                }
                let url = Config.dev_url + "/api-apps-v2/api/v2/energy/storage/favorite/remove"
                this.$axios.post(url,data).then(res=>{
                    this.$message.success(res.data.msg)
                    this.getData()
                })
            }else if (record.是否收藏 == 0) {
                let data = {
                    deptname:record.名称,
                    type:'1',
                    assetNumber:record.code,
                    userName:this.currentuserinfo.USERNAME,
                    typeName:"zonglan",
                }
                let url = Config.dev_url + "/api-apps-v2/api/v2/energy/storage/favorite/add"
                this.$axios.post(url,data).then(res=>{
                    this.$message.success(res.data.msg)
                    this.getData()
                })
            }
        },
        // 获取数据
        getData() {
            // //console.log(this.currentuserinfo.NICKNAME);
            this.detailInfo = {}
            // var list = [
            //     // { id: 1, 名称: "交流储能BMS", 总电压: "40", 电流: "34", soc: "64", soh:"0.0", 是否收藏:"0" },

            // ]
            this.isLoading = true
            let url = Config.dev_url + "/api-apps-v2/api/v2/energy/storage/overview"
            let data = {
                deptCode: this.currentSelectDeptInfo.CODE,
                assetNumber: this.input,
                type: this.searchObj.TYPE,
                userName:this.currentuserinfo.USERNAME
            }
            this.$axios.post(url, data).then(res => {
                this.detailInfo.list = res.data.data.data
                // //console.log(this.detailInfo.list);
                this.detailInfo.list.forEach(item => {
                    item.id=item.code
                    item.名称=item.name
                    item.soc = Number(item.soc).toFixed(2) 
                    item.电流 = Number(item.current).toFixed(2)
                    item.总电压 = Number(item.totalVoltage).toFixed(2)
                    item.soh = Number(item.soh).toFixed(2)
                    item.是否收藏 =item.isFavorite
   
                });
            }).catch((wrong) => { }).finally(() => {
                this.isLoading = false
            })

        },
        getElHeight() {
            if (!!this.$el) {
                // this.tableHeight = this.$el.clientHeight - 180
                this.tableHeight = this.$el.clientHeight - 180
            }
        },



    },
    mounted() {
        this.getOperateWidth()
        if (!this.option.visible) {
            this.getData()
        }
    }
}
</script>

<style lang="less" scoped>
.lvscBox18 {
  /deep/.ant-radio-group {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background: #f5f5f5;
    .ant-radio-button-wrapper {
      //background: inherit;
      //margin: 3px;
      border: 0;
      height: 26px;
      line-height: 26px;
      &::before {
        background-color: transparent;
      }
    }
    .ant-radio-button-wrapper-checked {
      background: #3366ff !important;
      border-radius: 2px;
      box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.15);

      font-size: 14px;
      font-family: ABBvoice_WCNSG_Rg;
      font-weight: 400;
      text-align: center;
      color: #fff;
      border: 0;
      &::before {
        background-color: transparent;
      }

    }
  }

    /deep/.el-input__inner {
        height: 32px;
    }

    .list-content {
        position: relative;
        height: inherit;
    }

    /deep/.ant-table-thead {

        tr {
            th {
                background: #f5f5f5;
                padding: 9px 16px;
            }
        }

    }

    /deep/.ant-table-body {
        .ant-table-column-sort {
            background: #fff;
        }

        .ant-table-thead {
            tr {
                th {
                    background: transparent;

                }
            }
        }

        .ant-table-tbody {
            td {
                padding: 8px 16px;

            }
        }
    }

    .icon-weishoucang {
        &:hover {
            color: #3366FF;
            cursor: pointer;
        }
    }

    .pagination {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-top: 16px;
        height: 33px;
        line-height: 33px;
        display: flex;
        justify-content: flex-end;

        .pagination-sizes {
            font-size: 14px;
            font-weight: 400;
            color: #1f1f1f;

            span {
                display: inline-block;
                line-height: 33px;
                border-bottom: 1px solid #bababa;
            }
        }

        .pagination-btngroup {
            margin-left: 41px;

            i {
                color: rgba(31, 31, 31, .16);

                &:nth-child(2) {
                    margin-left: 16px;
                    color: #1F1F1F;
                }
            }

        }
    }
}</style>
